@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: 16px;
  --font-inter: "Inter", sans-serif;
  --font-roboto: "Roboto", sans-serif;
  --font-roboto-mono: "Roboto Mono", monospace;
  --color-primary: 25 91 220;
  --color-on-primary: 255 255 255;
  --color-primary-container: 232 240 254;
  --color-on-primary-container: 12 42 102;
  --color-secondary: 61 74 143;
  --color-on-secondary: 255 255 255;
  --color-secondary-container: 231 233 249;
  --color-on-secondary-container: 26 31 60;
  --color-surface: 250 250 250;
  --color-on-surface: 17 24 39;
  --color-surface-variant: 223 222 255;
  --color-surface-dim: 209 215 235;
  --color-surface-bright: 248 249 252;
  --color-container-lowest: 255 255 255;
  --color-container-low: 245 247 250;
  --color-container-base: 241 243 247;
  --color-container-high: 230 233 239;
  --color-container-highest: 224 227 235;
  --color-on-surface-variant: 81 86 97;
  --color-outline: 100 105 115;
  --color-outline-variant: 210 212 218;
  --color-inverse-surface: 46 52 65;
  --color-inverse-on-surface: 237 240 247;
  --color-inverse-primary: 185 207 251;
  --color-container-lowest: 255 255 255;
  --color-container-low: 245 247 250;
  --color-container-base: 241 243 247;
  --color-container-high: 230 233 239;
  --color-container-highest: 224 227 235;
  --color-outline: 100 105 115;
  --color-outline-variant: 219 220 223;
  --color-success-container: 198 241 191;
  --color-on-success-container: 28 71 21;
  --color-error-container: 255 202 195;
  --color-on-error-container: 100 18 7;
  --color-warning-container: 255 220 195;
  --color-on-warning-container: 128 76 38;
  --color-neutral-container: 239 239 255;
  --color-on-neutral-container: 11 11 38;
  --color-success: 113 219 96;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
}

h2 {
  font-size: 2.5rem;
  font-weight: 600;
}

h3 {
  font-size: 2rem;
  font-weight: 600;
}

h4 {
  font-size: 1.5rem;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }
}

/* TIMELINE STYLING - END */

.ant-picker-ok > button,
.ant-table-filter-dropdown-btns > .ant-btn-primary {
  background-color: #1677ff;
}

.ant-picker-range {
  border: none;
}

.ant-table-thead > tr > .ant-table-cell {
  background-color: #e5ebf2 !important;
  color: #79879b !important;
}

.ant-table-thead > tr > .ant-table-cell p {
  text-align: left;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-blue-500);
  border-color: var(--color-blue-500);
}

.warning-table-row > td.ant-table-cell {
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px 10px #dedede;
  border: solid 7px transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border: solid 7px transparent;
  border-radius: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

body

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#scrollable::-webkit-scrollbar {
  display: none;
}

.calendar-root {
  --grid-gap: 2px;
  --grid-height: 700px;
  --text-color-light: rgba(0, 0, 0, 0.4);
  --text-color-primary: rgba(0, 0, 0, 0.7);
  --grid-background-color: rgb(211, 205, 198);
  --grid-foreground-color: white;
  --space-sm: 4px;
  --space-md: 8px;
  --space-lg: 16px;
  width: 100%;
}

.react-tel-input .flag-dropdown {
  border: 0 !important;
}

.react-tel-input .form-control {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  background: transparent !important;
  height: fit-content;
}

.react-tel-input input {
  width: 100% !important;
  border-bottom: 2px solid #dadada !important;
  height: fit-content;
}

.react-tel-input input:disabled {
  border-bottom: 0 !important;
  margin-left: -24px;
}

.react-tel-input .selected-flag {
  padding-left: 0px !important;
  width: fit-content;
}

.react-tel-input .selected-flag .arrow {
  display: none;
}

.react-tel-input input:focus {
  width: 100% !important;
  border-bottom-width: 2px !important;
  border-style: solid !important;
  border-color: "bg-blue-600" !important;
}

/* | Sun | Mon | Tue | Wed | Thu | Fri | Sat  */
.day-of-week-header-cell {
  color: var(--text-color-primary);
  background-color: var(--grid-foreground-color);
  padding: var(--space-md) 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.days-of-week,
.days-grid {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
}

.days-grid {
  height: var(--grid-height);
  position: relative;
  border: var(--grid-gap) solid var(--grid-background-color);
  background-color: var(--grid-background-color);
}

.day-grid-item-container {
  position: relative;
  background-color: var(--grid-foreground-color);
  display: flex;
  flex-direction: column;
}

.day-grid-item-container:not(.current-month) .day-grid-item-header {
  color: var(--text-color-light);
}

/* Position the day label within the day cell */
.day-grid-item-container .day-grid-item-header {
  color: var(--text-color-primary);
  padding: var(--space-md);
  padding-bottom: 0;
  flex-shrink: 0;
  font-weight: medium;
}

.day-grid-item-container > .day-content-wrapper {
  flex: 1;
  min-height: 0;
  position: relative;
}

.navigation-header {
  display: flex;
}

.navigation-header > * {
  margin-right: var(--space-md);
}

html,
body {
  padding: 0;
  margin: 0;
  color: #3b4c5c;
  font-family: "Inter", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style-type: none;
}

.custom-img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.unset-img {
  width: 100%;
}
.unset-img > div {
  position: unset !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.react-datepicker__input-container input {
  background-position: right;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  background-image: url("../public/icons/calendar-days.svg");
  cursor: pointer;
}

select {
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  background-image: url("../public/icons/chevron-down.svg");
  appearance: none;
  cursor: pointer;
  background: transparent;
}

.input-container .filled {
  transform: translate(0, 12px) scale(0.8);
}

.input-container label {
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input {
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container:focus-within label {
  transform: translate(0, 12px) scale(0.8);
}

.tox .tox-edit-area__iframe {
  background: transparent !important;
}

.Tabs {
  width: 80%;
  height: auto;
  min-height: 400px;
  border-radius: 2rem;
}

/* Tab Navigation */
.Tabs ul.nav {
  display: flex;
  flex-direction: row;
}

.Tabs ul.nav li {
  flex: 1 0 auto;
  list-style: none;
  text-align: center;
  cursor: pointer;
}

.ant-popover-inner {
  padding: 4px !important;
}

.DropdownMenuContent {
  width: var(--radix-dropdown-menu-trigger-width);
  max-height: var(--radix-dropdown-menu-content-available-height);
}

.PopoverContent {
  width: var(--radix-popover-trigger-width);
  max-height: var(--radix-popover-content-available-height);
}

@layer components {
  body {
    @apply bg-gray-100;
  }
  ::-webkit-scrollbar-thumb {
    @apply shadow-[inset_0_0_10px_10px] shadow-blue-500;
  }
  input:disabled,
  textarea {
    @apply bg-white;
  }
  input,
  textarea {
    @apply bg-transparent focus:bg-blue-100 focus:placeholder:text-black;
  }
  .button-effect {
    @apply cursor-pointer rounded-xl border-2 border-white bg-blue-600 text-center font-medium text-white hover:border-blue-600 hover:bg-white hover:text-blue-600;
  }
  .button-cancel {
    @apply cursor-pointer rounded-xl border-2 border-white bg-red-500 text-center font-medium text-white hover:border-red-700 hover:bg-white hover:text-red-700;
  }
  .Tabs ul.nav li {
    @apply border-b-2 border-white;
  }
  .Tabs ul.nav li:hover {
    @apply border-b-2 border-b-blue-600 text-blue-600;
  }
  .Tabs ul.nav li.active {
    @apply border-b-2 border-b-blue-600 font-medium text-blue-600;
  }
  .agency {
    @apply select-none content-center items-center border-b border-gray-300 px-2 text-center hover:bg-blue-100;
  }
  .agency-active {
    @apply border-blue-500 bg-blue-100 font-medium;
  }
  .card-shadow {
    @apply border-b-2 border-gray-300;
  }
  .line {
    @apply mb-10 mt-2 block;
  }
  .line h2 {
    @apply relative border-b border-gray-200 text-center text-xl;
  }
  .line h2 span {
    @apply relative top-3 bg-white px-4;
  }
  .minibid-budget li {
    @apply cursor-pointer rounded-3xl px-4 pb-4 pt-12 text-center font-medium shadow shadow-blue-200;
  }
  .minibid-budget li.active {
    @apply border-2 border-blue-700;
  }
  .subdepartment {
    @apply border-b border-gray-300 bg-gray-100 leading-8 last:border-0;
  }
  .subdepartment > .subdepartment {
    @apply border-b border-gray-300 bg-gray-200 leading-8 last:border-0;
  }
  .subdepartment input[type="checkbox"] {
    @apply mx-4;
  }
  .settings-input {
    @apply w-full rounded-xl border-b-2 border-gray-300 px-4 py-2;
  }
  .tab-item {
    @apply basis-4/12 cursor-pointer border-b border-r border-gray-300 px-4 py-2 text-center hover:text-blue-600;
  }
  .tab-item:last-child {
    @apply border-r-0;
  }
  .tab-item .active {
    @apply font-medium text-blue-600;
  }
  .action-button {
    @apply cursor-pointer rounded-3xl border-2 border-white bg-blue-600 px-4 py-2 text-white hover:border-2 hover:border-blue-500 hover:bg-white hover:text-blue-700;
  }
  .terms p {
    @apply pb-6 last:pb-0;
  }
  .add-timereg-wrapper {
    @apply mt-10 flex;
  }
  .other-leaves {
    @apply last-of-type:ml-4;
  }
  .input-space {
    @apply w-4 flex-shrink select-none text-xl font-medium;
  }
  .input-space .dot {
    @apply flex h-full items-end justify-center;
  }
  .textarea-wrapper {
    @apply flex-1 flex-row bg-white px-7 pt-5;
  }
  .buttons-wrapper {
    @apply flex flex-row bg-white px-7 py-5;
  }
  .timereg-button {
    @apply flex-1 cursor-pointer rounded-3xl py-2 text-center text-lg font-medium text-white;
  }
  .add-timereg {
    @apply first-of-type:mr-4;
  }
  .add-expenses {
    @apply mx-4;
  }
  .create-data {
    @apply flex-1 overflow-auto;
  }
  .create-data textarea {
    @apply h-20 w-full rounded-xl border-2 px-4 py-3 text-lg placeholder:text-slate-400;
  }
  .create-data .headline {
    @apply pl-3 text-lg font-medium;
  }
  .create-data .input-wrapper {
    @apply flex flex-row bg-white px-7 pt-5;
  }
  .create-data .currency {
    @apply pointer-events-none absolute top-0 h-full w-full items-center px-7 py-3 text-right text-lg font-medium;
  }
  .create-data .icon {
    @apply h-8 w-8;
  }
  .timereg-header {
    @apply flex h-12 items-center justify-start rounded-t-3xl px-7 py-4 text-white;
  }
  .timereg-input {
    @apply w-full rounded-xl border-2 px-4 py-2 text-lg placeholder:text-slate-400;
  }
  .calendar-row {
    @apply flex flex-row;
  }
  .expenses {
    @apply absolute left-[25%] top-2 z-20 flex h-2 w-2 translate-x-[-25%] rounded-full bg-[#F26311];
  }
  .expenses-info {
    @apply pointer-events-none absolute left-0 top-[-0.75rem] z-10 hidden w-auto select-none rounded-3xl bg-[#F26311] px-3 py-1 text-[0.80rem] font-medium text-white group-hover:flex;
  }
  .other-leave {
    @apply absolute right-[25%] top-2 z-10 flex h-2 w-2 translate-x-[25%] rounded-full bg-[#F2A411];
  }
  .leave-info {
    @apply pointer-events-none absolute left-0 top-[-0.75rem] z-10 hidden w-auto select-none rounded-3xl bg-[#F2A411] px-3 py-1 text-[0.80rem] font-medium text-white group-hover:flex;
  }
  .login button {
    @apply disabled:opacity-50;
  }
  .error-msg {
    @apply mb-4 flex rounded-lg bg-red-100 p-4 text-sm text-red-700 dark:bg-red-200 dark:text-red-800;
  }
  .input-container {
    @apply relative flex flex-col;
  }

  .input-container input {
    @apply focus:shadow-secondary h-12 rounded-3xl px-4 pb-1 pt-6 text-base leading-6 outline-none focus:shadow-[0_0px_0px_2px];
  }

  .input-container label {
    @apply pointer-events-none absolute left-4 text-base leading-4 text-gray-500 focus-within:text-red-500;
  }

  .files-uploaded img {
    @apply pb-4;
  }
  .files-uploaded svg {
    @apply fill-primary;
  }
  .email-input {
    @apply text-primary mt-4 box-border h-8 w-full appearance-none rounded-3xl border-2 border-solid border-gray-300 px-4 py-6 focus:border-blue-500 focus:outline-none;
  }
  .email-input.has-error {
    @apply border-red-500;
  }

  .email-error {
    @apply m-0 text-red-500;
  }
  .skill-item {
    @apply text-primary border-primary hover:bg-primary mb-1 ml-0 mr-1 mt-0 inline-flex h-6 items-center rounded-full border-2 px-2 pb-0 pt-0 text-xs hover:text-white;
  }
  .tag-item {
    @apply bg-primary mb-1 ml-0 mr-1 mt-0 inline-flex h-8 items-center rounded-3xl pb-0 pl-4 pr-1 pt-0 text-white;
  }
  .tag-item > .tag-button,
  .tag-item-need > .tag-button,
  .tag-item-nice > .tag-button {
    @apply text-primary ml-3 flex h-6 w-6 cursor-pointer items-center justify-center rounded-3xl border-none bg-white p-0 font-medium leading-6;
  }
  .tag-item-need {
    @apply bg-secondary mb-1 ml-0 mr-1 mt-0 inline-flex h-8 items-center rounded-3xl pb-0 pl-4 pr-1 pt-0 text-white;
  }
  .tag-item-nice {
    @apply mb-1 ml-0 mr-1 mt-0 inline-flex h-8 items-center rounded-3xl bg-blue-500 pb-0 pl-4 pr-1 pt-0 text-white;
  }
  .active-link {
    @apply border-b-2 border-blue-500 font-medium text-blue-500;
  }
  .sub-topbar a {
    @apply ml-2 cursor-pointer hover:text-blue-500;
  }
  .sub-topbar button a {
    @apply hover:text-white;
  }
  .sub-topbar > svg {
    @apply stroke-primary ml-4;
  }
  .editable:hover {
    @apply bg-5 bg-[url('../public/icons/pencil.svg')] bg-right bg-no-repeat;
  }
  .topbar-tooltip {
    @apply absolute top-14 m-2 w-auto min-w-max origin-left scale-0 rounded-md bg-blue-500 p-2 text-xs font-medium text-white shadow-md transition-all duration-100;
  }
  .sidebar-icon {
    @apply relative mx-auto mb-3 mt-3 flex h-12 w-12 cursor-pointer items-center justify-center rounded-3xl text-white transition-all duration-300 ease-linear hover:rounded-xl hover:bg-blue-500 hover:text-white;
  }

  #bar-chart-color {
    -webkit-appearance: none;
    padding: 0;
    border: none;
  }
  #bar-chart-color::-webkit-color-swatch {
    border: none;
    padding: 0;
  }
  #bar-chart-color::-webkit-color-swatch-wrapper {
    border: none;
    padding: 0;
  }

  .sidebar-tooltip {
    @apply absolute left-14 m-2 w-auto min-w-max origin-left scale-0 rounded-md bg-blue-500 p-2 text-xs font-medium text-white shadow-md transition-all duration-100;
  }
  .sub-topbar .std-button,
  .sub-topbar .act-button {
    @apply ml-2;
  }
  .flextribe-symbol svg {
    @apply mx-auto mb-12 mt-5;
  }
  .avatars {
    @apply inline-flex flex-row-reverse flex-wrap;
  }
  .avatar {
    @apply relative h-12 w-12;
  }
  .avatar:not(:last-child) {
    @apply ml-[-80px];
  }
  .signout .avatar:not(:last-child) {
    @apply ml-[0px];
  }
  .signout .sidebar-tooltip {
    @apply left-[-0.50rem] py-3;
  }
  .avatar-hover {
    @apply origin-left transition-all duration-100 hover:bg-blue-500;
  }
  .avatar img {
    @apply block w-full rounded-full;
  }
  .card {
    @apply mb-4 rounded-3xl bg-white p-4;
  }
  .form-label {
    @apply text-primary mb-2 block text-sm font-medium;
  }
  .field-input {
    @apply w-full border-b-2 py-1 text-sm outline-none transition-all focus:border-b-blue-500 focus:placeholder-gray-400 focus:outline-none;
  }
  .field-style {
    @apply mt-2 rounded-xl bg-white px-4 py-2 focus:bg-white;
  }
}

@tailwind utilities;

.popover-trigger-width {
  width: var(--radix-popover-trigger-width);
  max-height: var(--radix-popover-content-available-height);
}

.dropdown-trigger-width {
  width: var(--radix-dropdown-menu-trigger-width);
  max-height: var(--radix-dropdown-menu-content-available-height);
}

/*
* For rendering images inserted using the image plugin.
* Includes image captions using the HTML5 figure element.
*/

figure.image {
  display: inline-block;
  border: 1px solid gray;
  margin: 0 2px 0 1px;
  background: #f5f2f0;
}

figure.align-left {
  float: left;
}

figure.align-right {
  float: right;
}

figure.image img {
  margin: 8px 8px 0 8px;
}

figure.image figcaption {
  margin: 6px 8px 6px 8px;
  text-align: center;
}

/*
 Alignment using classes rather than inline styles
 check out the "formats" option
*/

img.align-left {
  float: left;
}

img.align-right {
  float: right;
}

/*
* Premium Plugins CSS
*/

/* The Table of Contents Plugin */

.mce-toc {
  border: 1px solid gray;
}

.mce-toc h2 {
  margin: 4px;
}

.mce-toc li {
  list-style-type: none;
}

/* The Checklist Plugin */

.tox-checklist > li:not(.tox-checklist--hidden) {
  list-style: none;
  margin: 0.25em 0;
  position: relative;
}
.tox-checklist > li:not(.tox-checklist--hidden)::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20id%3D%22checklist-unchecked%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20id%3D%22Rectangle%22%20width%3D%2215%22%20height%3D%2215%22%20x%3D%22.5%22%20y%3D%22.5%22%20fill-rule%3D%22nonzero%22%20stroke%3D%22%234C4C4C%22%20rx%3D%222%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
  cursor: pointer;
  height: 1em;
  margin-left: -1.5em;
  margin-top: 0.125em;
  position: absolute;
  width: 1em;
}
.tox-checklist li:not(.tox-checklist--hidden).tox-checklist--checked::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20id%3D%22checklist-checked%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20id%3D%22Rectangle%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%234099FF%22%20fill-rule%3D%22nonzero%22%20rx%3D%222%22%2F%3E%3Cpath%20id%3D%22Path%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%20d%3D%22M11.5703186%2C3.14417309%20C11.8516238%2C2.73724603%2012.4164781%2C2.62829933%2012.83558%2C2.89774797%20C13.260121%2C3.17069355%2013.3759736%2C3.72932262%2013.0909105%2C4.14168582%20L7.7580587%2C11.8560195%20C7.43776896%2C12.3193404%206.76483983%2C12.3852142%206.35607322%2C11.9948725%20L3.02491697%2C8.8138662%20C2.66090143%2C8.46625845%202.65798871%2C7.89594698%203.01850234%2C7.54483354%20C3.373942%2C7.19866177%203.94940006%2C7.19592841%204.30829608%2C7.5386474%20L6.85276923%2C9.9684299%20L11.5703186%2C3.14417309%20Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
}

.tox-tinymce {
  border: 0px !important;
}
